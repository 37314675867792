input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}


* {
  box-sizing: border-box;
  font-family: Pretendard, serif;
  margin: 0;
  padding: 0;
}
html,
body {
  width: 100%;
  height: 100%;
  font-family: Pretendard, serif;
  vertical-align: middle;
  padding: 0;
  margin: 0;
  color: #1c1b1f;
}

body {
  min-height: 100vh;
  min-height: -webkit-fill-available;
  /* ZOOM disable */
  touch-action: pan-x pan-y;
}

html,
body,
div,
span,
button,
textarea,
input,
select,
option,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
menu,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
main,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  font-family: Pretendard, serif;
  vertical-align: middle;
  font-weight: 500;
  font-size: 16px;
  color: #1c1b1f;
}

ul,
ol {
  list-style: none;
}

a {
  color: inherit;
  text-decoration: none;
}

span,
p {
  line-height: 1.5;
}

input,
textarea {
  -moz-user-select: auto;
  -webkit-user-select: auto;
  -ms-user-select: auto;
  user-select: auto;
  border: none;
  background: #fff;
}
input:focus {
  outline: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
form,
fieldset {
  border: none;
  width: 100%;
}
button {
  border: none;
  background: none;
  padding: 0;
  cursor: pointer;
  letter-spacing: -1px;
}
legend {
  display: none;
}

